import React from "react";
import { Helmet } from "react-helmet";
import { useConfig } from "hooks/use-config.hook";
import { translationHelper } from "helpers/translation.helper";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Layout from "../components/layout.component";
import { TranslationKeys } from "../interfaces/translation-keys.enum";
import { useTranslation } from "react-i18next";
import { CheckCircleIcon } from "@heroicons/react/solid";


const OrderConfirmation = (): JSX.Element => {
    const config = useConfig();

    const { t, i18n } = useTranslation();

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const order_number = urlParams.get("order_code") || "";

    return (
        <Layout>
            <Helmet>
                <title>{t(TranslationKeys.CART_ORDER_CONFIRMATION_TITLE)} - {translationHelper(config.title, i18n.language)}</title>
            </Helmet>
            <div
                className="w-full sm:rounded-md bg-white overflow-hidden sm:shadow py-2 flex justify-center content-center text-center">
                <div className="block">
                    <CheckCircleIcon className="w-20 h-20 text-green-600 inline-flex justify-center" />
                    <h1 className="text-xl mb-2">{t(TranslationKeys.CART_ORDER_CONFIRMATION_TITLE)}</h1>
                    <p className="mb-4">
                        {t(TranslationKeys.CART_ORDER_CONFIRMATION_ORDER_NUMBER, { order_number })}
                    </p>
                    <Link
                        to="/"
                        className="btn btn-primary inline-block text-center"
                    >
                        {t(TranslationKeys.COMMON_BACK_TO_HOME)}
                    </Link>
                </div>
            </div>
        </Layout>
    );
};

export default OrderConfirmation;
